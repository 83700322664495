<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="(val) => $emit('update:is-add-new-user-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">Member Info</h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(submitForm)"
          @reset.prevent="resetForm"
        >
          <!-- Full Name -->
          <validation-provider
            #default="validationContext"
            name="Full Name"
            rules="required"
          >
            <b-form-group label="Full Name" label-for="full-name">
              <b-form-input
                id="full-name"
                v-model="currentCategory.title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <validation-provider
            #default="validationContext"
            name="Full Name Unicode"
           
          >
            <b-form-group label="Full Name Unicode" label-for="full-name">
              <b-form-input
                id="full-name"
                v-model="currentCategory.unicode_title"
                autofocus
                :state="getValidationState(validationContext)"
                trim
                placeholder="John Doe"
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group label="Department" label-for="department-id">
            <department-picker
            :key="currentCategory.department"
              :initialCategories="currentCategory.department"
              @selectionChanged="onDepartmentSelectionChanged"
            >
            </department-picker>
          </b-form-group>

          <b-form-group label="Specialization" label-for="specialization-id">
            <specialization-picker
            :key="currentCategory.specialization"
              :initialCategories="currentCategory.specialization"
              @selectionChanged="onSpecializationSelectionChanged"
            >
            </specialization-picker>
          </b-form-group>

          <validation-provider
            #default="validationContext"
            name="Question"
        
          >
            <b-form-group
              label="About"
              label-for="about"
            >
           
          <quill-editor
     
     
       height="100"
      :options="snowOption"
       :content="currentCategory.about" 
    @change="onEditorChange($event)"
      @blur="onEditorBlur($event)"
    @focus="onEditorFocus($event)"
    @ready="onEditorReady($event)"
    />


              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>


          <validation-provider
            #default="validationContext"
            name="About Unicode"
        
          >
            <b-form-group
              label="About Unicode"
              label-for="aboutunicode"
            >
           
          <quill-editor
     
     
       height="100"
      :options="snowOption"
       :content="currentCategory.unicode_about" 
    @change="onUnicodeEditorChange($event)"
      @blur="onEditorBlur($event)"
    @focus="onEditorFocus($event)"
    @ready="onEditorReady($event)"
    />


              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

       

          <!-- Email -->
          <validation-provider
            #default="validationContext"
            name="Email"
            
          >
            <b-form-group label="Email" label-for="email">
              <b-form-input
                id="email"
                v-model="currentCategory.email"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
   <!-- Username -->
   <validation-provider
            #default="validationContext"
            name="Mobile"
         
          >
            <b-form-group label="Mobile" label-for="mobile">
              <b-form-input
                id="mobile"
                v-model="currentCategory.mobile"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
          <!-- Company -->
          
         
          <b-media no-body>
      <b-media-aside>
        <b-form-group
              label="Doctor Photo"
              label-for="photo"
            >
        <b-link>
          <b-img
            ref="previewEl" 
            rounded
            :src="currentCategory.photo"
            height="100"
            alt=""
            width="100"
          />
          
        </b-link>
      </b-form-group>
        <!--/ avatar -->
      </b-media-aside>

      <b-media-body class="mt-75 ml-75">
        <!-- upload button -->
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          size="sm"
          class="mb-75 mr-75"
          :state="Boolean(file)"
          v-model="file"
          @click="$refs.refInputEl.$el.click()"
        >
          Upload
        </b-button>
        <b-form-file
          ref="refInputEl"
          v-model="profileFile"
          accept=".jpg, .png, .gif"
          :hidden="true"
          plain
          @input="fileSelected"
        />
        <!--/ upload button -->

        <!-- reset -->

        <!--/ reset -->
        <b-card-text>Allowed JPG, GIF or PNG. Max size of 2 MB</b-card-text>
      </b-media-body>
    </b-media>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              v-if="currentCategory==null|| currentCategory.id==null"
            >
                     Add
            </b-button>

            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              @click="updatecategory"
            v-if="currentCategory!=null&&currentCategory.id!=null"
            >
                      Update 
            </b-button>

              <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
                  class="mr-2"
                  v-if="currentCategory!=null&& currentCategory.id!=null"
              variant="outline-danger"
              @click="deletecategory"
            >
              Delete
            </b-button>

            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BMediaAside,BFormFile,  BCard, BLink,
  BMediaBody,
  BImg,  BMedia,BCardText,
} from "bootstrap-vue";
import { quillEditor } from 'vue-quill-editor'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import countries from "@/@fake-db/data/other/countries";
import store from "@/store";
import DepartmentPicker from "@/views/clinic/clinicdepartments/components/DepartmentPicker.vue";
import SpecializationPicker from "@/views/clinic/specializations/components/SpecializationPicker.vue";
import { useInputImageRenderer } from "@core/comp-functions/forms/form-utils";
import axios from "@axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";


export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    quillEditor,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
    DepartmentPicker,
    SpecializationPicker,
    BMediaAside,BFormFile,  BCard, BLink,
  BMediaBody,
  BImg,  BMedia,BCardText,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isAddNewUserSidebarActive",
    event: "update:is-add-new-user-sidebar-active",
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    currentMember: {
      type: Object,
      required: true,
    },
    roleOptions: {
      type: Array,
      required: true,
    },
    planOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      file: null,
      profileFile: null,
      isUpdated: false,
      required,
      alphaNum,
      email,
      countries,
      currentCategory: {},
      snowOption: {
        theme: 'snow',
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'align': [] }],
        
           
        [{ 'color': [] }, { 'background': [] }],
            ['clean']
          ]
        }
      },
    };
  },

  watch: {
    currentMember: {
      handler: function (n, o) {
        //console.log("new member: ", n);

        if (n == null || n == undefined) {
          this.currentCategory = {
            id: null,
            title: null,
            unicode_title: null,
            about: null,
            unicode_about: null,
            department: null,
            specialization: null,
            email: null,
            mobile: null,
            twitter: null,
            facebook: null,
            linkedin: null,
            instagram: null,
            registerid: null,
            membershipid: null,
            graduate: null,
            graduateDate: null,
            graduateFrom: null,
            availableForAppointment: true,
            photo: null,
            deleted: false,
          };
        } else {
          //console.log("new member: ", n);
          this.currentCategory = n;
        }
      },
      deep: true,
    },
  },

  methods: {
    resetFile() {
      this.file = null;
      this.profileFile = null;
      this.currentCategory.photo = null;
    },
    clearFiles() {
      this.$refs["file-input"].reset();
    },

    fileSelected(payload) {
      const formData = new FormData();
      formData.append("file", payload);
      formData.append("courseId", this.courseId);
      formData.append("acc", 3);
      formData.append("platform", "webadmin");
      this.isLoading = true;

      axios
        .post("/upload/admin", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          //console.log(response);
          //console.log( response.data.url);
          //console.log(  this.userData);
          this.currentCategory.photo = response.data.url;
          this.isUpdated = true;
          this.isLoading = false;
          
        })
        .catch((error) => {
          //console.log(error);
          this.isLoading = false;
          this.$bvToast.toast(`${error.response.data.message}`, {
            title: "Error",
            autoHideDelay: 5000,
            appendToast: true,
            variant: "danger",
            solid: true,
          });
        });
    },
    

    uploadFile(paylod) {
      this.optionsLocal = JSON.parse(JSON.stringify(this.generalData.data));
    },
    onEditorBlur(quill) {
    //    //console.log('editor blur!', quill)
      },
      onEditorFocus(quill) {
     //   //console.log('editor focus!', quill)
      },
      onEditorReady(quill) {
   //     //console.log('editor ready!', quill)
      },
      onEditorChange({ quill, html, text }) {
   //     //console.log('editor change!', quill, html, text)
   this.isUpdated=true;
        this.currentCategory.about= html
      },
      onUnicodeEditorChange({ quill, html, text }) {
   //     //console.log('editor change!', quill, html, text)
   this.isUpdated=true;
        this.currentCategory.unicode_about= html
      },
    onDepartmentSelectionChanged(payload) {
      //console.log("currentCategory selected");
      //console.log(payload);
      this.currentCategory.departmentId = payload.id;
      this.currentCategory.department = payload;
    },
    onSpecializationSelectionChanged(payload) {
      //console.log("currentCategory selected");
      //console.log(payload);
      this.currentCategory.specializationId = payload.id;
      this.currentCategory.specialization = payload;
    },
    selectionChanged(selectedGroup) {
      // //console.log(selectedGroup);

      if (selectedGroup == null || selectedGroup == undefined) {
        this.currentCategory.upperCategory = null;
      } else {
        this.currentCategory.upperCategory = selectedGroup.id;
      }
    },
    deletecategory() {
      this.currentCategory.deleted = true;

      this.$store
        .dispatch("apps-clinicmember/updateBoardMember", this.currentCategory)
        .then((result) => {
          //console.log(result);

          this.$emit("refetch-data");
          this.$emit("update:is-add-new-user-sidebar-active", false);
        });
    },
    updatecategory() {
      //console.log("sent data");
      //console.log(this.currentCategory);

      this.$store
        .dispatch("apps-clinicmember/updateBoardMember", this.currentCategory)
        .then((result) => {
          //console.log(result);

          this.$emit("refetch-data");
          this.$emit("update:is-add-new-user-sidebar-active", false);
        });
    },
    

    submitForm() {
      //console.log(this.currentCategory);

      this.$store
        .dispatch("apps-clinicmember/createBoardMember", this.currentCategory)
        .then((result) => {
          //console.log(result);

          this.$emit("refetch-data");
          this.$emit("update:is-add-new-user-sidebar-active", false);
        });
    },
  },
  setup(props, { emit }) {


    const refInputEl = ref(null);
    const previewEl = ref(null);

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, previewEl);


    const blankUserData = {
      id: null,
            title: null,
            unicode_title: null,
            about: null,
            unicode_about: null,
            department: null,
            specialization: null,
            email: null,
            mobile: null,
            twitter: null,
            facebook: null,
            linkedin: null,
            instagram: null,
            registerid: null,
            membershipid: null,
            graduate: null,
            graduateDate: null,
            graduateFrom: null,
            availableForAppointment: true,
            photo: null,
            deleted: false,
    };

    const currentCategory = ref(JSON.parse(JSON.stringify(blankUserData)));
    const resetuserData = () => {
      currentCategory.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const onSubmit = () => {
      store
        .dispatch("apps-clinicmember/createBoardMember", currentCategory.value)
        .then(() => {
          emit("refetch-data");
          emit("update:is-add-new-user-sidebar-active", false);
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    return {
      currentCategory,
      onSubmit,
      refInputEl,
      previewEl,
      inputImageRenderer,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
 
@import '@core/scss/vue/libs/quill.scss';

.ql-editor{
    min-height:100px;
}
</style>
