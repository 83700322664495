import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAllDoctors(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/clinic/admin/doctors',{ params: queryParams }  )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    createBoardMember(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('clinic/admin/doctor',  userData  )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
      
    updateBoardMember(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .put('clinic/admin/doctor',  userData  )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    
   
    
    


  },
}
